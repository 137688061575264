import "../style/loansAccordian.css";
import React, { useState } from "react";
import loansAccordian from "../assests/img/loansbgnew.png";
const ContaintInAccordian = [
  {
    Title: "Culture in the United States",
    points: [
      "The United States boasts diverse cultural influences, shaped by its history as a melting pot of immigrants. English remains the dominant language, though Spanish is widely spoken in southern states. With communities rooted in Europe, Asia, and beyond, American culture embraces various traditions and cuisines, reflecting its rich diversity.",
    ],
  },

  {
    Title: "Choosing a Good University in the United States",
    points: [
      "Selecting a university involves considering academic reputation, campus life, and location. Institutions like Harvard, MIT, and Stanford are globally renowned, offering vibrant student communities and cutting-edge research opportunities.",
      "1)To choose a university in the United States, start by creating a shortlist based on your preferences, such as location, subject, or university reputation.",
      "2)Research each university thoroughly, considering admission criteria, costs, and deadlines. Focus on academic aspects like program structure and placement opportunities to align with your learning style and goals.",
      "3)Additionally, consider the cultural and living experience you desire, whether it's in a vibrant city or a campus-based setting.",
      // gap
      "Some of the best universities in the United States are:",
      "1)Massachusetts Institute of Technology",
      "2)Princeton University",
      "3)Harvard University",
      "4)Stanford University",
      "5)University of California",
      "6)Berkeley(UCB)",
    ],
  },
  {
    Title: "Average Tuition Fee in the United States:",
    points: [
      "Tuition fees vary widely across universities and programs. On average, undergraduate tuition ranges from approximate $20,000 to $50,000 per year, while graduate programs can cost approximate $30,000 to $60,000 annually.",
    ],
  },

  {
    Title: "Scholarships in the United States",
    points: [
      "Explore various scholarships, grants, and financial aid options available for international students. Learn how to research and apply for scholarships that can help offset the cost of your education.",
      " Types of scholarships: Scholarships for international students in the USA include government-funded initiatives like the Hubert Humphrey Fellowship Program and the Fulbright Foreign Student Program, as well as institution-specific scholarships and merit-based awards.",
      "Finding Scholarships: Most colleges offer scholarship programs tailored for international students. Institutions' financial aid websites and scholarship databases are excellent places to start your search for available scholarships.",
      " Application Process: Scholarship application processes vary. Some scholarships require completing an application form, while others may involve additional steps like submitting essays, recommendation letters, and proof of academic achievements.",
      "Preparing Your Application: Applicants typically need to provide academic transcripts, standardized test scores (such as SAT, ACT, GRE, or GMAT), letters of recommendation, personal statements, and sometimes financial documentation to demonstrate need.",
    ],
  },
  {
    Title: "Language Requirement",
    points: [
      "While English proficiency is essential, language requirements vary among universities and programs. Many institutions accept standardized English language test scores such as TOEFL, IELTS, PTE Academic or C1 Advanced or C2 Proficiency. Also Duolingo English Test has gained wider acceptance and is an easy-to-access test.",
    ],
  },
  {
    Title: "Student Visa",
    points: [
      "International students require an F-1 student visa to study in the US. The application process involves submitting a Form DS-160, attending a visa interview, and demonstrating financial solvency.",
    ],
  },
  {
    Title: "Cost of Living",
    points: [
      "The cost of living varies based on location and lifestyle. On average, students can expect to spend approximate $10,000 to $20,000 annually on accommodation, meals, transportation, and other expenses.",
    ],
  },

  {
    Title: "Jobs and Placement",
    points: [
      "International students in the US can work on their F-1 Visa, but with restrictions. They are limited to 20 hours per week during term time and can work full-time during vacations if registered for the next semester. Jobs are typically limited to on-campus positions, with exceptions for practical training related to their degree after one year of study. Off-campus work requires approval from US Citizenship and Immigration Services. Job opportunities can be found on university websites, through student services departments, and by networking within departments and campus facilities. Being proactive in seeking opportunities and leveraging university resources can help international students secure suitable employment while studying in the US.",
    ],
  },
  {
    Title: "Accommodations",
    points: [
      "Housing options include on-campus dormitories, off-campus apartments, and homestays. Prices and availability vary by location, with on-campus housing offering convenience and community.",
    ],
  },
];
const Accordian = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    // <div className="border border-primary rounded-3 p-2 mx-4 my-2 ">
    //   <div className="">
    //     <div
    //       className="d-flex justify-content-between"
    //       onClick={handleDropdown}
    //     >
    //       <div className="px-2 ">
    //         <h4 className="text-primary pt-2 ">{item.Title}</h4>
    //       </div>
    //       <div
    //         style={{ cursor: "pointer", marginBottom: "0px" }}
    //         className="pt-3"
    //       >
    //         <i
    //           className={`bi bi-caret-${
    //             isOpen ? "up" : "down"
    //           }-fill text-primary`}
    //         ></i>
    //       </div>
    //     </div>
    //     {isOpen && (
    //       <div className="px-3">
    //         <div style={{ listStyle: "none", padding: 0 }}>
    //           {item.points.map((point, index) => (
    //             <ul key={index} style={{ marginBottom: "5px" }}>
    //               <li>{point}</li>
    //             </ul>
    //           ))}
    //         </div>
    //       </div>
    //     )}
    //   </div>
    // </div>
    <div className="border border-primary rounded-3 p-2 mx-4 my-2 ">
      <div className="">
        <div
          className="d-flex justify-content-between"
          onClick={handleDropdown}
        >
          <div className="px-2 ">
            <h4 className="text-primary pt-2 ">{item.Title}</h4>
          </div>
          <div
            style={{ cursor: "pointer", marginBottom: "0px" }}
            className="pt-3"
          >
            <i
              className={`bi bi-caret-${
                isOpen ? "up" : "down"
              }-fill text-primary`}
            ></i>
          </div>
        </div>
        {isOpen && (
          <div className="px-3">
            <div style={{ listStyle: "none", padding: 0 }}>
              {item.points.map((point, index) => (
                <div key={index} style={{ marginBottom: "5px" }}>
                  {point.startsWith("1)") ||
                  point.startsWith("2)") ||
                  point.startsWith("3)") ||
                  point.startsWith("4)") ||
                  point.startsWith("5)") ||
                  point.startsWith("6)") ? (
                    <ul key={index} style={{ marginBottom: "5px" }}>
                      <p className="ms-4 mb-0 text-break ">
                        {point.substring(point.indexOf(")") + 1)}
                      </p>
                    </ul>
                  ) : (
                    <ul key={index} style={{ marginBottom: "5px" }}>
                      <li className="fw-semibold">{point}</li>
                    </ul>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Loan_AccordianIND = () => {
  return (
    <div className="container my-2">
      <div
        className="text-sm-start ms-0 ps-0 z-10"
        style={{
          height: "100%",
          width: "100%",
          backgroundImage: "url(" + loansAccordian + ")",
          backgroundColor: "lightgray",
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <h1 className="text-primary p-4">
          UNLOCKING FINANCIAL AID FOR YOUR EDUCATION IN THE USA
        </h1>

        {/* <Accordian ContaintInAccordian={ContaintInAccordian} /> */}
        <span className="container d-flex justify-content-center px-4">
          Studying in the United States can be a transformative experience,
          offering an enriching cultural exchange and world-class education.
          However, financing your education may require careful planning.
          Explore the following topics to gain valuable insights into studying
          in the US and financing your journey.
        </span>
        {ContaintInAccordian.map((item, i) => (
          <Accordian item={item} key={i} />
        ))}
        <>
          {/* <div className="">
            <p className="mx-md-5 mx-3">
              Studying in the United States can be a transformative experience,
              offering an enriching cultural exchange and world-class education.
              However, financing your education may require careful planning.
              Explore the following topics to gain valuable insights into
              studying in the US and financing your journey.
            </p>
            <h2 className="mx-md-5 mx-3 text-primary">
              Culture in the United States
            </h2>
            <p className="mx-md-5 mx-3">
              The United States boasts diverse cultural influences, shaped by
              its history as a melting pot of immigrants. English remains the
              dominant language, though Spanish is widely spoken in southern
              states. With communities rooted in Europe, Asia, and beyond,
              American culture embraces various traditions and cuisines,
              reflecting its rich diversity.
            </p>

            <h2 className="mx-md-5 mx-3 text-primary">
              Choosing a Good University in the United States
            </h2>
            <p className="mx-md-5 mx-3">
              Selecting a university involves considering academic reputation,
              campus life, and location. Institutions like Harvard, MIT, and
              Stanford are globally renowned, offering vibrant student
              communities and cutting-edge research opportunities.
              <li>
                To choose a university in the United States, start by creating a
                shortlist based on your preferences, such as location, subject,
                or university reputation.{" "}
              </li>
              <li>
                Research each university thoroughly, considering admission
                criteria, costs, and deadlines. Focus on academic aspects like
                program structure and placement opportunities to align with your
                learning style and goals.
              </li>
              <li>
                {" "}
                Additionally, consider the cultural and living experience you
                desire, whether it's in a vibrant city or a campus-based
                setting.
              </li>
              <br />
              Some of the best universities in the United States are:
              <li>Massachusetts Institute of Technology</li>
              <li>Princeton University </li>
              <li> Harvard University </li>
              <li>Stanford University </li>
              <li>University of California</li>
              <li> Berkeley(UCB)</li>
            </p>
            <h2 className="mx-md-5 mx-3 text-primary">
              Average Tuition Fee in the United States:
            </h2>
            <p className="mx-md-5 mx-3">
              Tuition fees vary widely across universities and programs. On
              average, undergraduate tuition ranges from approximate $20,000 to
              $50,000 per year, while graduate programs can cost approximate
              $30,000 to $60,000 annually.
            </p>
            <h2 className="mx-md-5 mx-3 text-primary">
              Scholarships in the United States
            </h2>
            <p className="mx-md-5 mx-3">
              <li>
                Types of scholarships: Scholarships for international students
                in the USA include government-funded initiatives like the Hubert
                Humphrey Fellowship Program and the Fulbright Foreign Student
                Program, as well as institution-specific scholarships and
                merit-based awards.
              </li>
              <li>
                Finding Scholarships: Most colleges offer scholarship programs
                tailored for international students. Institutions' financial aid
                websites and scholarship databases are excellent places to start
                your search for available scholarships.
              </li>
              <li>
                {" "}
                Application Process: Scholarship application processes vary.
                Some scholarships require completing an application form, while
                others may involve additional steps like submitting essays,
                recommendation letters, and proof of academic achievements.
              </li>
              <li>
                Preparing Your Application: Applicants typically need to provide
                academic transcripts, standardized test scores (such as SAT,
                ACT, GRE, or GMAT), letters of recommendation, personal
                statements, and sometimes financial documentation to demonstrate
                need.
              </li>
            </p>

            <h2 className="mx-md-5 mx-3 text-primary">Language Requirement</h2>
            <p className="mx-md-5 mx-3">
              While English proficiency is essential, language requirements vary
              among universities and programs. Many institutions accept
              standardized English language test scores such as TOEFL, IELTS,
              PTE Academic or C1 Advanced or C2 Proficiency. Also Duolingo
              English Test has gained wider acceptance and is an easy-to-access
              test.
            </p>
            <h2 className="mx-md-5 mx-3 text-primary">Student Visa</h2>
            <p className="mx-md-5 mx-3">
              International students require an F-1 student visa to study in the
              US. The application process involves submitting a Form DS-160,
              attending a visa interview, and demonstrating financial solvency.
            </p>
            <h2 className="mx-md-5 mx-3 text-primary">Cost of Living</h2>
            <p className="mx-md-5 mx-3">
              The cost of living varies based on location and lifestyle. On
              average, students can expect to spend $10,000 to $20,000 annually
              on accommodation, meals, transportation, and other expenses.
            </p>
            <h2 className="mx-md-5 mx-3 text-primary">Jobs and Placement</h2>
            <p className="mx-md-5 mx-3">
              International students in the US can work on their F-1 Visa, but
              with restrictions. They are limited to 20 hours per week during
              term time and can work full-time during vacations if registered
              for the next semester. Jobs are typically limited to on-campus
              positions, with exceptions for practical training related to their
              degree after one year of study. Off-campus work requires approval
              from US Citizenship and Immigration Services. Job opportunities
              can be found on university websites, through student services
              departments, and by networking within departments and campus
              facilities. Being proactive in seeking opportunities and
              leveraging university resources can help international students
              secure suitable employment while studying in the US.
            </p>
            <h2 className="mx-md-5 mx-3 text-primary">Accommodations</h2>
            <p className="mx-md-5 mx-3 pb-3">
              Housing options include on-campus dormitories, off-campus
              apartments, and homestays. Prices and availability vary by
              location, with on-campus housing offering convenience and
              community.
            </p>
          </div> */}
        </>
      </div>
    </div>
    // <div className="loan-background-container ">
    //   <h1 className="text-primary">
    //     Unlocking Financial Aid for Your Education in the USA
    //   </h1>

    //   {/* <Accordian ContaintInAccordian={ContaintInAccordian} /> */}
    //   {ContaintInAccordian.map((item) => (
    //     <Accordian item={item} />
    //   ))}
    // </div>
  );
};

export default Loan_AccordianIND;

{
  /* {item.map(({ Title }) => (
            <React.Fragment key={Title}>
              <h3 className="text-primary">{Title}</h3>
              <i
                className="bi bi-caret-down-fill text-primary"
                onClick={handleDropdown}
              ></i>
            </React.Fragment>
          ))}
        </div>
        <div>
          {item.map(({ points }) => (
            <ul key={points.join("-")}>
              {points.map((point, index) => (
                <li key={index}>
                  <span>{point}</span>
                </li>
              ))}
            </ul>
          ))} */
}
